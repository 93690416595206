<template>
  <div>
    <div v-if="type === 'variable'">
      <el-popover
        placement="top-end"
        title="Values"
        width="200"
        trigger="hover"
      >
        <div
          v-for="(val, index) in varValues"
          :key="index"
        >
          {{ val }}
        </div>
        <el-select
          slot="reference"
          v-model="attrValue"
          filterable
          clearable
          placeholder="Select variable"
        >
          <el-option
            v-for="item in varOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-popover>
      {{ extra }}
    </div>
    <div v-if="type === 'text' || type === 'url'">
      <el-input v-model="attrValue" /> {{ extra }}
    </div>
    <div v-if="type === 'number'">
      <el-input-number
        v-model="attrValue"
        controls-position="right"
      /> {{ extra }}
    </div>
    <div v-if="type === 'checkmark'">
      <el-radio-group
        v-model="attrValue"
      >
        <el-radio-button
          label="none"
        >
          None
        </el-radio-button>
        <el-radio-button
          label="checked"
        >
          Checked
        </el-radio-button>
        <el-radio-button
          label="unchecked"
        >
          Unchecked
        </el-radio-button>
      </el-radio-group>
    </div>
    <div
      v-if="type === 'rating'"
      style="margin-top: 9px"
    >
      <el-rate
        v-model="attrValue"
        show-score
        allow-half
      />
      <div>
        <el-input-number
          v-model="attrValue"
          size="mini"
          controls-position="right"
        />
      </div>
    </div>
    <div v-if="type === 'list'">
      <div
        v-for="(val, index) in decodeList(attrValue)"
        :key="index"
        style="margin-bottom: 5px"
      >
        <el-input
          :value="val"
          @input="inputListValue(index, $event)"
        />
      </div>

      <el-button
        size="mini"
        @click="addListItem()"
      >
        Add
      </el-button>

      <el-button
        size="mini"
        @click="removeListItem()"
      >
        Remove
      </el-button>
    </div>
    <div v-if="type === 'image'">
      <div
        v-for="(val, index) in decodeList(attrValue)"
        :key="index"
        style="cursor: pointer"
        @click="removeImage(index)"
      >
        <el-image
          v-if="val"
          class="fl"
          style="width: 60px; height: 60px; margin-right: 5px"
          :src="imageUrls[index]"
          fit="cover"
        />
      </div>

      <div
        v-if="attrValue"
        style="margin-bottom: 10px"
        class="clearfix"
      ></div>

      <el-popover
        v-model="imgSelVisible"
        placement="right"
        width="400"
        trigger="click"
      >
        <div>
          <el-input
            v-model="filter.search"
            placeholder="Search"
            style="width: 220px; margin-bottom: 8px"
            class="filter-item"
            clearable
            size="mini"
            @clear="onFilter"
            @keyup.enter.native="onFilter"
            @input="onFilter"
          />

          <div
            style="max-height: 250px; overflow-y: auto"
          >
            <div
              v-for="item in imagesList"
              :key="item.mediaUid"
              class="fl"
              style="margin-right: 5px"
            >
              <el-image
                style="width: 60px; height: 60px; cursor: pointer"
                :src="item.image.location"
                fit="cover"
                @click="addImage(item.mediaUid)"
              />
            </div>
          </div>

          <div class="clearfix"></div>

          <div style="text-align: right; margin-top: 25px">
            <el-button
              size="mini"
              type="primary"
              @click="imgSelVisible = false"
            >
              Close
            </el-button>
          </div>
        </div>

        <el-button
          slot="reference"
          size="mini"
          type="primary"
          @click="getImageList()"
        >
          Select Image
        </el-button>
      </el-popover>
    </div>
  </div>
</template>

<script>
import { listImages } from '@/api/gamePage';
import { getMedia } from '@/api/media';

export default {
  name: 'AttrInput',
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: {
      type: [String, Number, Boolean],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    extra: {
      type: String,
      default: '',
    },
    variables: {
      type: [Array, Object, null],
      default: null,
    },
    gameUid: {
      type: String,
      default: '',
    },
  },
  data() {
    const defSortBy = 'createdAt';
    const defSortDir = 'desc';

    return {
      imgSelVisible: false,
      attrValue: this.type === 'text' || this.type === 'url' ? String(this.value) : this.value,
      imagesList: [],
      imagesListTotal: 0,
      imageListLoading: false,
      listQuery: {
        pageNumber: 1,
        pageSize: 100,
        sortBy: defSortBy,
        sortDir: defSortDir,
      },
      imageUrls: [],
      filter: {
        search: '',
      },
      links: [],
      varOptions: [],
      varValues: 'Not selected',
    };
  },
  watch: {
    value(newVal) {
      this.attrValue = newVal;

      this.initComponent();
    },
    attrValue(newVal) {
      this.$emit('input', newVal);
    },
  },
  created() {
    this.initComponent();
  },
  methods: {
    initComponent() {
      if (this.type === 'checkmark'
        && (typeof this.attrValue === 'string' && !['checked', 'unchecked', 'none'].includes(this.attrValue))) {
        this.attrValue = 'none';
      }
      if (this.type === 'rating' && this.attrValue < 0) {
        this.attrValue = 0;
      }
      if (this.type === 'image') {
        this.loadImageUrls();
      }
      if (this.type === 'variable') {
        this.loadVariables();
        this.getVariableValues();
      }
    },
    decodeList(val) {
      // is not string
      if (typeof val !== 'string') {
        return [''];
      }

      return val.split('^');
    },
    addListItem() {
      this.attrValue += '^';
    },
    removeListItem() {
      // remove last item starting with ^
      this.attrValue = this.attrValue.replace(/\^[^^]*$/, '');
    },
    inputListValue(index, val) {
      const list = this.decodeList(this.attrValue);
      // filter out ^ from val
      list[index] = val.replace(/\^/g, '');
      this.attrValue = list.join('^');
    },
    async addImage(mediaUid) {
      this.attrValue += `^${mediaUid}`;
      this.imgSelVisible = false;

      await this.loadImageUrls();
    },
    removeImage(index) {
      if (this.attrValue.includes('^')) {
        this.attrValue = this.attrValue.split('^').filter((_, i) => i !== index).join('^');
      } else {
        this.attrValue = '';
      }
    },
    async getImageList() {
      try {
        this.imageListLoading = true;

        this.imagesList = [];
        this.listQuery.pageNumber = 1;

        const data = await listImages(this.listQuery);
        const { items, pageInfo } = data.page;

        this.imagesList = items;
        this.imagesListTotal = pageInfo.itemsAvailable;
        this.imageListLoading = false;
      } catch (e) {
        this.imageListLoading = false;
      }
    },
    async getMedia(mediaUid) {
      if (!mediaUid) {
        return '';
      }

      try {
        const { media } = await getMedia({ mediaUid });

        return media?.image?.location;
      } catch (e) {
        return '';
      }
    },
    async loadImageUrls() {
      const values = this.decodeList(this.attrValue);
      this.imageUrls = await Promise.all(values.map((val) => this.getMedia(val)));
    },
    onFilter() {
      const filter = Object.keys(this.filter).reduce((acc, key) => {
        if (this.filter[key] !== '') {
          acc[key] = this.filter[key];
        }

        return acc;
      }, {});

      this.listQuery.filter = JSON.stringify(filter);
      this.getImageList();
    },
    loadVariables() {
      if (!this.variables?.[0]?.values) {
        return;
      }

      this.varOptions = [];

      this.varOptions.push({
        value: '@games',
        label: '@games',
      });
      this.varOptions.push({
        value: '@payments',
        label: '@payments',
      });
      this.varOptions.push({
        value: '@deposits',
        label: '@deposits',
      });

      this.variables[0].values.forEach((variable) => {
        if (variable.name && this.gameUid && (variable.gameUid === this.gameUid || !variable.gameUid)) {
          this.varOptions.push({
            value: variable.name,
            label: variable.name,
          });
        }
      });
    },
    getVariableValues() {
      if (!this.variables?.[0]?.values || !this.attrValue) {
        this.varValues = 'Not selected';
      }

      const result = [];

      this.variables.forEach((item) => {
        let line = `${item.langCode}: `;
        item.values.forEach((val) => {
          if (val.name === this.attrValue) {
            line += val.value ? val.value : '-';
          }
        });

        result.push(line);
      });

      this.varValues = result;
    },
  },
};
</script>
