<template>
  <div class="flex-form">
    <el-form
      ref="gamePageForm"
      v-loading="loading"
      :model="gamePageForm"
      label-width="170px"
      :rules="rules"
      autocomplete="off"
    >
      <el-form-item>
        <h2 v-if="isEditMode">
          Edit Game Page
        </h2>
        <h2 v-else-if="!isEditMode">
          Add Game Page
        </h2>
      </el-form-item>

      <el-tabs
        v-model="formTabsValue"
        class="el-form-item__content"
      >
        <el-tab-pane
          label="Main"
          name="main"
        >
          <el-form-item
            v-if="isEditMode"
            label="Uid"
          >
            {{ gamePageUid }}
          </el-form-item>

          <el-form-item
            label="Active"
            prop="active"
          >
            <el-switch
              v-model="gamePageForm.isActive"
            />
          </el-form-item>

          <el-form-item
            label="Game"
            prop="gameUid"
          >
            <el-select
              v-model="gamePageForm.gameUid"
              filterable
            >
              <el-option
                v-for="item in games"
                :key="item.gameUid"
                :label="item.title"
                :value="item.gameUid"
              />
            </el-select>
          </el-form-item>

          <el-form-item
            label="Icon"
          >
            <upload-media
              ref="iconImage"
              :image-url="iconUrl"
              :params="{ contentType: 'GAME_PAGE_ICON' }"
              @success="handleIconUploadSuccess"
              @remove="handleIconUploadRemove"
            />
          </el-form-item>

          <el-tabs
            v-model="activeLang"
            type="card"
            closable
            @edit="handleTabsEdit"
          >
            <el-tab-pane
              v-for="(item, index) in gamePageForm.content"
              :key="item.index"
              :label="langName(item.langCode)"
              :name="item.langCode"
            >
              <el-form-item
                :label="`Name [${item.langCode}]`"
                :prop="'content.' + index + '.name'"
              >
                <el-input
                  v-model="gamePageForm.content[index].name"
                  @input="handleNameInput(index, item.langCode)"
                />
              </el-form-item>

              <el-form-item
                :label="`Relative Url [${item.langCode}]`"
                :prop="'content.' + index + '.relativeUrl'"
              >
                <RelativeUrl
                  v-model="gamePageForm.content[index].relativeUrl"
                  @generate-relative-url="handleRelativeUrl($event, index, gamePageForm.content[index].name)"
                  @set-relative-url="setRelativeUrl($event, index)"
                />
              </el-form-item>

              <el-form-item
                :label="`Title [${item.langCode}]`"
                :prop="'content.' + index + '.title'"
              >
                <el-input v-model="gamePageForm.content[index].title" />
              </el-form-item>

              <el-form-item
                :label="`Content [${item.langCode}]`"
                :prop="'content.' + index + '.content'"
              >
                <Editor
                  v-model="gamePageForm.content[index].content"
                />
              </el-form-item>

              <el-form-item
                :label="`Content 2 [${item.langCode}]`"
                :prop="'content.' + index + '.content2'"
              >
                <Editor
                  v-model="gamePageForm.content[index].content2"
                />
              </el-form-item>

              <el-form-item
                :label="`Meta Title [${item.langCode}]`"
                :prop="'content.' + index + '.metaTitle'"
              >
                <el-input v-model="gamePageForm.content[index].metaTitle" />
              </el-form-item>

              <el-form-item
                :label="`Meta Description [${item.langCode}]`"
                :prop="'content.' + index + '.metaDescription'"
              >
                <el-input
                  v-model="gamePageForm.content[index].metaDescription"
                  type="textarea"
                  resize="none"
                  :autosize="{ minRows: 2, maxRows: 8}"
                />
              </el-form-item>
            </el-tab-pane>
          </el-tabs>
        </el-tab-pane>

        <el-tab-pane
          label="Shop Attributes"
          name="attributes"
        >
          <div
            v-for="(attribute, index) in gamePageForm.attributes"
            :key="index"
          >
            <div
              v-if="index > 0"
              style="height: 3px; background-color: #d0d6db; margin-left: 170px;"
            ></div>

            <el-divider
              content-position="left"
            >
              <span
                style="color: #e6a23cff"
                :class="getTypeIcon(gamePageForm.attributes[index].type)"
              ></span>
              Attribute {{ index + 1 }} {{ gamePageForm.attributes[index].content.length ? ` - ${gamePageForm.attributes[index].content[0].name}` : '' }}
            </el-divider>

            <el-form-item
              label="Type"
              :prop="'attributes.' + index + '.type'"
            >
              <el-select
                v-model="gamePageForm.attributes[index].type"
                filterable
              >
                <el-option
                  v-for="item in types"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-form-item>

            <el-form-item
              :label="`Name`"
              :prop="'attributes.' + index + '.name'"
            >
              <el-tabs
                v-model="activeLangAttr"
                type="border-card"
              >
                <el-tab-pane
                  v-for="(item, index2) in gamePageForm.attributes[index].content"
                  :key="index2"
                  :label="langNameAttr(index, item.langCode)"
                  :name="item.langCode"
                >
                  <el-input
                    v-model="gamePageForm.attributes[index].content[index2].name"
                    style="width: 75%"
                    placeholder="Name"
                  />
                  <el-input
                    v-model="gamePageForm.attributes[index].content[index2].ext"
                    style="width: 23%; float: right"
                    placeholder="Extra"
                  />
                </el-tab-pane>
              </el-tabs>
            </el-form-item>

            <el-form-item>
              <el-button
                size="mini"
                type="success"
                @click="addAttribute(index)"
              >
                Add
              </el-button>

              <el-button
                :disabled="gamePageForm.attributes.length === 1"
                size="mini"
                type="danger"
                @click="removeAttribute(index)"
              >
                Remove
              </el-button>

              <el-button
                :disabled="index === 0"
                size="mini"
                icon="el-icon-arrow-up"
                @click="moveAttributeUp(index)"
              />
              <el-button
                :disabled="index === gamePageForm.attributes.length - 1"
                size="mini"
                icon="el-icon-arrow-down"
                @click="moveAttributeDown(index)"
              />
            </el-form-item>
          </div>
        </el-tab-pane>
      </el-tabs>

      <el-form-item class="flex-form__action-buttons">
        <el-button
          type="primary"
          :loading="saving"
          @click="onSubmit"
        >
          Save
        </el-button>

        <el-button @click="onCancel">
          Cancel
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {
  getGamePage,
  updateGamePage,
  createGamePage,
  getAttributeTypes,
  generateRelativeUrl,
} from '@/api/gamePage';
import { getAllGames } from '@/api/game';
import { cloneDeep } from 'lodash';
import { makeUpdateObj } from '@/utils';
import { getAllLanguages } from '@/api/language';
import Editor from '@/components/Editor';
import RelativeUrl from '@/components/RelativeUrl';
import UploadMedia from '@/components/UploadMedia';

export default {
  name: 'GamePageDetails',
  components: { UploadMedia, Editor, RelativeUrl },
  props: {
    isEditMode: {
      default: false,
      type: Boolean,
    },
    gamePageUid: {
      default: null,
      type: String,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      gamesLoading: false,
      saving: false,
      gamePageForm: {
        gameUid: null,
        content: [],
        coverMediaUid: null,
        isActive: true,
        attributes: [],
      },
      types: [],
      gamePage: {},
      games: {},
      langOptions: [],
      activeLang: '',
      activeLangAttr: '',
      formTabsValue: 'main',
      relativeUrlEdited: {},
      rules: {
        gameUid: [{
          required: true,
          trigger: 'blur',
          message: 'Game is required',
        }],
      },
    };
  },
  computed: {
    iconUrl() {
      return this.gamePageForm?.media?.icon?.image?.location;
    },
  },
  watch: {
    gamePageUid(val) {
      if (val) {
        this.getGamePage(val);
      } else {
        this.initForm();
      }
    },
  },
  created() {
    if (this.isEditMode) {
      this.getGamePage(this.gamePageUid);
    } else {
      this.initForm();
    }

    this.getGames();
  },
  methods: {
    defaultAttributes() {
      return {
        content: [],
        type: '',
      };
    },
    async initForm() {
      this.resetForm();

      await this.getLanguages();
      await this.getTypes();

      this.gamePageForm.attributes = [{ ...this.defaultAttributes() }];

      this.langOptions.forEach((item) => {
        this.gamePageForm.content.push({
          langCode: item.value,
          name: '',
          title: '',
          relativeUrl: '',
          content: '',
          content2: '',
          metaTitle: '',
          metaDescription: '',
        });

        this.gamePageForm.attributes[0].content.push({
          langCode: item.value,
          name: '',
          ext: '',
        });
      });

      this.activeLang = this.langOptions[0].value;
      this.activeLangAttr = this.langOptions[0].value;
    },
    langName(code) {
      const langName = this.langOptions.find((item) => item.value === code)?.label;
      const name = this.gamePageForm.content.find((item) => item.langCode === code)?.name;

      if (!name.trim()) {
        return `! ${langName}`;
      }

      return langName;
    },
    langNameAttr(index, code) {
      const langName = this.langOptions.find((item) => item.value === code)?.label;
      const name = this.gamePageForm.attributes[index].content.find((item) => item.langCode === code)?.name;

      if (!name.trim()) {
        return `! ${langName}`;
      }

      return langName;
    },
    async getLanguages() {
      try {
        const data = await getAllLanguages();
        this.langOptions = data.languages.map((item) => ({
          value: item.langCode,
          label: item.langName,
        }));
      } catch (e) {
        this.langOptions = [];
      }
    },
    async getGamePage(gamePageUid) {
      try {
        this.resetForm();
        this.loading = true;

        await this.getLanguages();
        await this.getTypes();

        const data = await getGamePage({ gamePageUid });

        if (!data.gamePage?.attributes || !data.gamePage.attributes?.length) {
          data.gamePage.attributes = [{ ...this.defaultAttributes() }];

          data.gamePage.attributes[0].content.push({
            langCode: this.langOptions[0].value,
            name: '',
            ext: '',
          });
        }

        this.gamePage = cloneDeep(data.gamePage);
        this.gamePageForm = data.gamePage;

        // order content by langOptions
        this.gamePageForm.content = this.langOptions.map((item) => {
          const content = this.gamePageForm.content.find((c) => c.langCode === item.value);
          if (!content) {
            return {
              langCode: item.value,
              name: '',
              title: '',
              relativeUrl: '',
              content: '',
              content2: '',
              metaTitle: '',
              metaDescription: '',
            };
          }

          return content;
        });

        this.gamePageForm.attributes = this.gamePageForm.attributes.map((attr) => {
          // eslint-disable-next-line no-param-reassign
          attr.content = this.langOptions.map((item) => {
            const content = attr.content.find((c) => c.langCode === item.value);
            if (!content) {
              return {
                langCode: item.value,
                name: '',
                ext: '',
              };
            }

            return content;
          });

          return attr;
        });

        this.activeLang = this.gamePageForm.content
          .find((item) => item.name.trim())?.langCode || this.langOptions[0].value;
        this.activeLangAttr = this.gamePageForm.attributes[0].content
          .find((item) => item.name.trim())?.langCode || this.langOptions[0].value;

        this.loading = false;
      } catch (e) {
        this.loading = false;

        this.onCancel();
        // console.error(e);
      }
    },
    async getTypes() {
      try {
        const data = await getAttributeTypes();
        this.types = data.types;
      } catch (e) {
        this.types = [];
      }
    },
    async getGames() {
      try {
        this.gamesLoading = true;
        const data = await getAllGames();

        this.games = data.games;
        this.gamesLoading = false;
      } catch (e) {
        this.gamesLoading = false;

        this.onCancel();
      }
    },
    async onEditSubmit() {
      this.$refs.gamePageForm.validate(async (valid) => {
        if (valid) {
          const updatedGamePage = makeUpdateObj(this.gamePage, this.gamePageForm);
          if (Object.keys(updatedGamePage).length > 0) {
            try {
              this.saving = true;
              updatedGamePage.gamePageUid = this.gamePageUid;

              await updateGamePage(updatedGamePage);

              this.$message({
                type: 'success',
                message: 'Data has been saved successfully',
              });

              this.saving = false;

              this.$emit('update');
            } catch (e) {
              await this.getGamePage(this.gamePageUid);

              this.saving = false;
            }
          }

          this.close();
        }
      });
    },
    async onAddSubmit() {
      this.$refs.gamePageForm.validate(async (valid) => {
        if (valid) {
          try {
            this.saving = true;
            await createGamePage(this.gamePageForm);

            this.$message({
              type: 'success',
              message: 'Game page has been added successfully',
            });

            this.resetForm();
            this.saving = false;

            this.$emit('update');
            this.close();
          } catch (e) {
            this.saving = false;
          }
        }
      });
    },
    async onSubmit() {
      if (this.isEditMode) {
        await this.onEditSubmit();
      } else {
        await this.onAddSubmit();
      }
    },
    resetForm() {
      this.relativeUrlEdited = {};
      this.gamePageForm.content = [];
      this.gamePageForm.attributes = [];
      this.gamePageForm.media = {};
      this.langOptions = [];

      this.$nextTick(() => {
        this.$refs.iconImage.reset();
        this.formTabsValue = 'main';
        this.$refs.gamePageForm.resetFields();
      });
    },
    handleCoverUploadSuccess(data) {
      this.gamePageForm.iconMediaUid = data.mediaUid;
    },
    addAttribute(index) {
      const newAttr = {
        type: '',
        content: [],
      };
      this.langOptions.forEach((item) => {
        newAttr.content.push({
          langCode: item.value,
          name: '',
          ext: '',
        });
      });

      this.gamePageForm.attributes.splice(index + 1, 0, newAttr);
    },
    removeAttribute(index) {
      if (this.gamePageForm.attributes.length === 1) {
        return;
      }

      this.gamePageForm.attributes.splice(index, 1);
    },
    moveAttributeUp(index) {
      if (index === 0) {
        return;
      }

      const temp = this.gamePageForm.attributes[index];
      this.$set(this.gamePageForm.attributes, index, this.gamePageForm.attributes[index - 1]);
      this.$set(this.gamePageForm.attributes, index - 1, temp);
    },
    moveAttributeDown(index) {
      if (index === this.gamePageForm.attributes.length - 1) {
        return;
      }

      const temp = this.gamePageForm.attributes[index];
      this.$set(this.gamePageForm.attributes, index, this.gamePageForm.attributes[index + 1]);
      this.$set(this.gamePageForm.attributes, index + 1, temp);
    },
    onCancel() {
      this.close();
    },
    close() {
      this.$emit('update:visible', false);
    },
    handleContentRules(index, lang, value) {
      if (value.trim()) {
        this.addContentRules(index, lang);
      } else {
        this.removeStaticPageRules(index);
      }
    },
    addContentRules(index, lang) {
      if (this.rules[`content.${index}.name`] || index < 0) {
        return;
      }

      this.$set(this.rules, `content.${index}.name`, [{
        required: true,
        trigger: 'blur',
        message: `Name [${lang}] is required`,
      }]);
      this.$set(this.rules, `content.${index}.title`, [{
        required: true,
        trigger: 'blur',
        message: `Title [${lang}] is required`,
      }]);
      this.$set(this.rules, `content.${index}.relativeUrl`, [{
        required: true,
        trigger: 'blur',
        message: `Relative Url [${lang}] is required`,
      }]);
      this.$set(this.rules, `content.${index}.content`, [{
        required: true,
        trigger: 'blur',
        message: `Content [${lang}] is required`,
      }]);

      setTimeout(() => {
        this.$refs.gamePageForm.clearValidate();
      });
    },
    removeStaticPageRules(index) {
      this.$delete(this.rules, `content.${index}.name`);
      this.$delete(this.rules, `content.${index}.title`);
      this.$delete(this.rules, `content.${index}.relativeUrl`);
      this.$delete(this.rules, `content.${index}.content`);
    },
    handleTabsEdit(targetName) {
      // remove content
      const index = this.gamePageForm.content.findIndex((item) => item.langCode === targetName);

      this.gamePageForm.content[index].name = '';
      this.gamePageForm.content[index].title = '';
      this.gamePageForm.content[index].relativeUrl = '';
      this.gamePageForm.content[index].metaTitle = '';
      this.gamePageForm.content[index].metaDescription = '';
      this.gamePageForm.content[index].content = '';
      this.gamePageForm.content[index].content2 = '';

      // this.removeStaticPageRules(index);
    },
    async handleRelativeUrl(editRelativeUrl, index, value) {
      if (this.gamePage?.content?.[index]?.relativeUrl && !editRelativeUrl) {
        return;
      }

      if (value && value.trim().length) {
        const data = await generateRelativeUrl({
          name: value,
          gamePageUid: this.gamePageUid,
          langCode: this.gamePageForm.content[index].langCode,
        });

        if (data.relativeUrl) {
          this.gamePageForm.content[index].relativeUrl = data.relativeUrl;
        }
      }
    },
    handleNameInput(index, langCode) {
      this.handleContentRules(index, langCode, this.gamePageForm.content[index].name);
      if (!this.relativeUrlEdited[index]) {
        this.handleRelativeUrl(false, index, this.gamePageForm.content[index].name);
      }
    },
    setRelativeUrl(value, index) {
      this.relativeUrlEdited[index] = true;
      this.handleRelativeUrl(true, index, value);
    },
    handleIconUploadSuccess(data) {
      this.gamePageForm.iconMediaUid = data.mediaUid;
    },
    handleIconUploadRemove() {
      this.gamePageForm.iconMediaUid = null;
      this.gamePageForm.media.icon = null;
    },
    getTypeIcon(type) {
      switch (type) {
        case 'text':
          return 'fas fa-text';
        case 'number':
          return 'fas fa-sort-numeric-down';
        case 'checkmark':
          return 'far fa-check-circle';
        case 'rating':
          return 'far fa-star';
        case 'image':
          return 'fal fa-image';
        case 'url':
          return 'fas fa-link';
        case 'list':
          return 'fas fa-list';
        case 'variable':
          return 'far fa-brackets-curly';

        default:
          return 'fal fa-file';
      }
    },
  },
};
</script>
