<template>
  <div class="flex-form">
    <el-form
      ref="gamePageForm"
      v-loading="loading"
      :model="gamePageForm"
      label-width="170px"
      :rules="rules"
      autocomplete="off"
    >
      <el-form-item>
        <h2>
          Edit Shop Values
        </h2>
      </el-form-item>

      <el-form-item
        v-if="gamePageForm.gameUid"
        label="Game Page"
      >
        {{ gamePageForm.content[0].name + ' (' + game?.title + ')' }}
      </el-form-item>

      <div v-if="noAttributes">
        <el-alert
          title="No attributes found"
          type="warning"
          show-icon
          :closable="false"
        />
      </div>

      <div v-else>
        <div
          v-for="(shop, vIndex) in gamePageForm.shops"
          :key="vIndex"
        >
          <div
            v-if="vIndex > 0"
            style="height: 3px; background-color: #d0d6db; margin-left: 170px; margin-bottom: 30px"
          ></div>

          <el-divider content-position="left">
            <h3>
              Shop {{ vIndex + 1 }} {{ gamePageForm.shops[vIndex].shopUid ? `- ${shopName(gamePageForm.shops[vIndex].shopUid)}` : '' }}
            </h3>
          </el-divider>

          <el-form-item
            label="Shop"
            prop="shopUid"
          >
            <el-select
              v-model="gamePageForm.shops[vIndex].shopUid"
              filterable
            >
              <el-option
                v-for="item in shops"
                :key="item.shopUid"
                :label="item.shopName"
                :value="item.shopUid"
              />
            </el-select>
          </el-form-item>

          <div
            v-for="(attribute, aIndex) in gamePageForm.attributes"
            :key="aIndex"
          >
            <el-form-item
              v-if="langAttr.includes(attribute?.type)"
              :label="`${attribute.content?.[0]?.name}`"
              prop="name"
            >
              <el-tabs
                v-if="gamePageForm.shops[vIndex].values[aIndex]"
                v-model="activeLang"
                type="border-card"
              >
                <el-tab-pane
                  v-for="(item, cIndex) in attribute.content"
                  :key="item.index"
                  :label="langName(gamePageForm.shops[vIndex].values[aIndex].content, item.langCode)"
                  :name="item.langCode"
                >
                  <div v-if="gamePageForm.shops[vIndex].values[aIndex].content?.[cIndex]">
                    <attr-input
                      v-model="gamePageForm.shops[vIndex].values[aIndex].content[cIndex].value"
                      :type="attribute?.type"
                      :extra="gamePageForm.shops[vIndex].values[aIndex].content[0]?.ext"
                      :variables="variables[gamePageForm.shops[vIndex].shopUid] || []"
                      :game-uid="gamePageForm.gameUid"
                    />
                  </div>
                </el-tab-pane>
              </el-tabs>
            </el-form-item>

            <div v-else>
              <div v-if="gamePageForm.shops[vIndex].values[aIndex]?.content?.[0]">
                <el-form-item
                  :label="`${attribute.content?.[0]?.name}`"
                  prop="name"
                >
                  <attr-input
                    v-model="gamePageForm.shops[vIndex].values[aIndex].content[0].value"
                    :type="attribute?.type"
                    :extra="attribute.content[0]?.ext"
                    :variables="variables[gamePageForm.shops[vIndex].shopUid] || []"
                    :game-uid="gamePageForm.gameUid"
                    @input="onAttrInputChange(gamePageForm.shops[vIndex].values[aIndex].content)"
                  />
                </el-form-item>
              </div>
            </div>

            <!-- <el-card
              class="box-card"
              shadow="hover"
              style="margin-left: 170px; margin-bottom: 15px"
            >
              <div
                slot="header"
                class="clearfix"
              >
                <strong>{{ attribute.content?.[0]?.name }}</strong>
              </div>
              <div>
                <div
                  v-for="(item, cIndex) in attribute.content"
                  :key="item.index"
                >
                  <div>
                    <el-form-item
                      :label="`${langName(gamePageForm.shops[vIndex].values[aIndex].content, item.langCode)}`"
                      prop="name"
                    >
                      <el-input v-model="gamePageForm.shops[vIndex].values[aIndex].content[cIndex].value" />
                    </el-form-item>
                  </div>
                </div>
              </div>
            </el-card> -->
          </div>

          <el-form-item>
            <el-button
              size="mini"
              type="success"
              @click="addValues(vIndex)"
            >
              Add
            </el-button>

            <el-button
              :disabled="gamePageForm.shops.length === 1"
              size="mini"
              type="danger"
              @click="removeValues(vIndex)"
            >
              Remove
            </el-button>

            <el-button
              :disabled="vIndex === 0"
              size="mini"
              icon="el-icon-arrow-up"
              @click="moveValuesUp(vIndex)"
            />
            <el-button
              :disabled="vIndex === gamePageForm.shops.length - 1"
              size="mini"
              icon="el-icon-arrow-down"
              @click="moveValuesDown(vIndex)"
            />

            <el-checkbox
              v-model="gamePageForm.shops[vIndex].isFeatured"
              class="fr"
            >
              Is Featured
            </el-checkbox>
          </el-form-item>
        </div>
      </div>

      <el-form-item class="flex-form__action-buttons">
        <el-button
          type="primary"
          :loading="saving"
          :disabled="noAttributes"
          @click="onSubmit"
        >
          Save
        </el-button>

        <el-button @click="onCancel">
          Cancel
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { getGamePage, updateGamePage } from '@/api/gamePage';
import { getAllShops, getAllVariables } from '@/api/shop';
import { getGame } from '@/api/game';
import { cloneDeep } from 'lodash';
import { makeUpdateObj } from '@/utils';
import { getAllLanguages } from '@/api/language';
import AttrInput from './attrInput';
// import UploadMedia from '@/components/UploadMedia';

export default {
  name: 'ShopValuesDetails',
  components: { AttrInput },
  props: {
    gamePageUid: {
      default: null,
      type: String,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      shopsLoading: false,
      saving: false,
      noAttributes: false,
      gamePageForm: {
        name: '',
        isActive: true,
        attributes: [],
        shops: [],
      },
      gamePage: {},
      game: {},
      shops: {},
      variables: {},
      activeLang: '',
      langOptions: [],
      langAttr: ['text', 'list'],
      rules: {
        gameUid: [{
          required: true,
          trigger: 'blur',
          message: 'Game is required',
        }],
      },
    };
  },
  computed: {
    coverUrl() {
      return this.gamePageForm?.media?.cover?.image?.location;
    },
  },
  watch: {
    visible(val) {
      if (val) {
        this.getGamePage(this.gamePageUid);
      }
    },
  },
  async created() {
    await this.getVariables();
    await this.getShops();
    await this.getGamePage(this.gamePageUid);
  },
  methods: {
    /* filterShops() {
      const shopUids = this.gamePageForm.shops.map((shop) => shop.shopUid);
      return this.shops.filter((shop) => !shopUids.includes(shop.shopUid));
    }, */
    defaultValues(attributes = []) {
      const values = [];

      if (attributes?.length) {
        attributes.forEach((attr) => {
          values.push({
            gameAttrUid: attr.gameAttrUid,
            content: attr.content.map((item) => ({
              langCode: item.langCode,
              value: '',
              ext: '',
            })),
          });

          this.langOptions.forEach((item) => {
            const content = attr.content.find((c) => c.langCode === item.value);
            if (!content) {
              attr.content.push({
                langCode: item.value,
                value: '',
                ext: '',
              });
            }
          });
        });
      }

      return {
        shopUid: '',
        isFeatured: false,
        values,
      };
    },
    shopName(shopUid) {
      return this.shops.find((item) => item.shopUid === shopUid)?.shopName;
    },
    langName(content, code) {
      const langName = this.langOptions.find((item) => item.value === code)?.label;
      const value = content.find((item) => item.langCode === code)?.value;

      if (!value) {
        return `! ${langName}`;
      }

      return langName;
    },
    async getLanguages() {
      try {
        const data = await getAllLanguages();
        this.langOptions = data.languages.map((item) => ({
          value: item.langCode,
          label: item.langName,
        }));
      } catch (e) {
        this.langOptions = [];
      }
    },
    async getGame(gameUid) {
      try {
        const data = await getGame({ gameUid });
        this.game = data.game;
      } catch (e) {
        this.game = {};
      }
    },
    async getVariables() {
      try {
        const data = await getAllVariables();

        this.variables = data.variables;
      } catch (e) {
        this.variables = {};
      }
    },
    async getGamePage(gamePageUid) {
      try {
        this.resetForm();
        this.loading = true;

        await this.getLanguages();

        const data = await getGamePage({ gamePageUid });

        await this.getGame(data.gamePage.gameUid);

        if (!data.gamePage?.attributes) {
          data.gamePage.attributes = [];
        }

        if (data.gamePage.attributes.length === 0) {
          this.noAttributes = true;
        }

        if (!data.gamePage?.shops || data.gamePage.shops.length === 0) {
          data.gamePage.shops = [{ ...this.defaultValues(data.gamePage.attributes) }];
        } else {
          data.gamePage.shops.forEach((shop, index) => {
            const values = [];
            data.gamePage.attributes.forEach((attr, attrIndex) => {
              const value = shop.values.find((v) => v.gameAttrUid === attr.gameAttrUid);
              if (!value) {
                values.push({
                  gameAttrUid: attr.gameAttrUid,
                  content: this.langOptions.map((item) => ({
                    langCode: item.value,
                    value: '',
                  })),
                });
              } else {
                this.langOptions.forEach((item) => {
                  const content = value.content.find((c) => c.langCode === item.value);
                  if (!content) {
                    value.content.push({
                      langCode: item.value,
                      value: '',
                    });
                  }
                });

                values.push(value);
              }

              this.langOptions.forEach((item) => {
                const content = attr.content.find((c) => c.langCode === item.value);
                if (!content) {
                  data.gamePage.attributes[attrIndex].content.push({
                    langCode: item.value,
                    name: '',
                    ext: '',
                  });
                }
              });
            });

            data.gamePage.shops[index].values = values;
          });
        }

        this.gamePage = cloneDeep(data.gamePage);
        this.gamePageForm = data.gamePage;

        this.activeLang = this.langOptions[0].value;

        this.loading = false;
      } catch (e) {
        this.loading = false;

        this.onCancel();
      }
    },
    async getShops() {
      try {
        this.shopsLoading = true;
        const data = await getAllShops();

        this.shops = data.shops;
        this.shopsLoading = false;
      } catch (e) {
        this.shopsLoading = false;

        this.onCancel();
      }
    },
    async onEditSubmit() {
      this.$refs.gamePageForm.validate(async (valid) => {
        if (valid) {
          const updatedGamePage = makeUpdateObj(this.gamePage, this.gamePageForm);
          if (Object.keys(updatedGamePage).length > 0) {
            try {
              this.saving = true;
              updatedGamePage.gamePageUid = this.gamePageUid;

              await updateGamePage(updatedGamePage);

              this.$message({
                type: 'success',
                message: 'Data has been saved successfully',
              });

              this.saving = false;

              this.$emit('update');
            } catch (e) {
              await this.getGamePage(this.gamePageUid);

              this.saving = false;
            }
          }

          this.close();
        }
      });
    },
    async onSubmit() {
      await this.onEditSubmit();
    },
    resetForm() {
      this.$nextTick(() => {
        this.formTabsValue = 'main';
        this.noAttributes = false;
        this.$refs.gamePageForm.resetFields();
      });
    },
    onCancel() {
      this.close();
    },
    close() {
      this.$emit('update:visible', false);
    },
    addValues(index) {
      this.gamePageForm.shops.splice(index + 1, 0, {
        ...this.defaultValues(this.gamePageForm.attributes),
      });
    },
    removeValues(index) {
      if (this.gamePageForm.shops.length === 1) {
        return;
      }

      this.gamePageForm.shops.splice(index, 1);
    },
    moveValuesUp(index) {
      if (index === 0) {
        return;
      }

      const temp = this.gamePageForm.shops[index];
      this.$set(this.gamePageForm.shops, index, this.gamePageForm.shops[index - 1]);
      this.$set(this.gamePageForm.shops, index - 1, temp);
    },
    moveValuesDown(index) {
      if (index === this.gamePageForm.shops.length - 1) {
        return;
      }

      const temp = this.gamePageForm.shops[index];
      this.$set(this.gamePageForm.shops, index, this.gamePageForm.shops[index + 1]);
      this.$set(this.gamePageForm.shops, index + 1, temp);
    },
    onAttrInputChange(content) {
      if (content?.[0]?.value) {
        content.forEach((item) => {
          // eslint-disable-next-line no-param-reassign
          item.value = content[0].value;
        });
      }
    },
  },
};
</script>
